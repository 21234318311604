import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Input, message, Checkbox, DatePicker } from 'antd';
import { ref, get, update } from "firebase/database";
import { database, firestore } from '../firebase';
import moment from 'moment';
import { collection, query, where, getDocs, doc, getDoc ,updateDoc} from "firebase/firestore";

const Przelewy = () => {
    const [driversData, setDriversData] = useState([]);
    const [selectedDrivers, setSelectedDrivers] = useState([]);
    const [isGenerateModalVisible, setIsGenerateModalVisible] = useState(false);
    const [isGenerateZUSModalVisible, setIsGenerateZUSModalVisible] = useState(false);
    const [isBalancePopupVisible, setIsBalancePopupVisible] = useState(false);
    const [isBankAccountModalVisible, setIsBankAccountModalVisible] = useState(false);
    const [popupDriverId, setPopupDriverId] = useState('');
    const [balanceInput, setBalanceInput] = useState('');
    const [bankAccountInput, setBankAccountInput] = useState('');
    const [balanceAction, setBalanceAction] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [searchText, setSearchText] = useState(''); // Добавлено состояние для поиска
    const [filteredDriversData, setFilteredDriversData] = useState([]); // Добавлено состояние для фильтрованных данных

    useEffect(() => {
        updateTable();
    }, []);

    useEffect(() => {
        handleSearch(searchText); // Выполняем фильтрацию при изменении searchText
    }, [searchText, driversData]);

    const updateTable = async () => {
        const tbodyData = [];
        let totalDrivers = 0;
        let totalBankAccounts = 0;
        let totalNormalStatus = 0;
        let totalDuplicateStatus = 0;
        let totalNoAccount = 0;
        let totalBalance = 0;

        const driverRef = ref(database, 'drivers');
        const driverSnapshot = await get(driverRef);  // Используем get для однократного получения данных

        const driverDataPromises = [];

        driverSnapshot.forEach((driverSnapshot) => {
            const driverData = driverSnapshot.val();
            const driverID = driverSnapshot.key;
            const driverBalance = parseFloat(driverData['balance']).toFixed(2);

            totalDrivers++;
            totalBalance += parseFloat(driverBalance);

            const driverRecord = {
                key: driverID,
                driverID: driverID,
                balance: driverBalance,
                uzDriverId: "Brak konta użytkownika",
                bankAccount: "brak",
                accountStatus: "Brak",
            };

            const driverDataPromise = new Promise(async (resolve) => {
                const querySnapshot = await getDocs(query(collection(firestore, 'users'), where('driverId', '==', driverID)));

                if (querySnapshot.empty) {
                    totalNoAccount++;
                    resolve(driverRecord);
                } else if (querySnapshot.size === 1) {
                    const userDoc = querySnapshot.docs[0];
                    const userID = userDoc.id;
                    driverRecord.uzDriverId = userID;

                    const danePodatkoweRef = doc(firestore, `users/${userID}/dokumenty/dane_podatkowe`);
                    const danePodatkoweDoc = await getDoc(danePodatkoweRef);

                    if (danePodatkoweDoc.exists() && danePodatkoweDoc.data().rachunekBankowy) {
                        driverRecord.bankAccount = danePodatkoweDoc.data().rachunekBankowy;
                        totalBankAccounts++;
                    }

                    driverRecord.accountStatus = "Normal";
                    totalNormalStatus++;
                    resolve(driverRecord);
                } else {
                    driverRecord.uzDriverId = "Duplikat konta użytkownika";
                    driverRecord.accountStatus = "Duplikat";
                    totalDuplicateStatus++;
                    resolve(driverRecord);
                }
            });

            driverDataPromises.push(driverDataPromise);
        });

        const resolvedData = await Promise.all(driverDataPromises);
        setDriversData(resolvedData);
        setFilteredDriversData(resolvedData); // Устанавливаем отфильтрованные данные
    };

    const handleSearch = (value) => {
        setSearchText(value);
        const filteredData = driversData.filter((driver) => {
            return (
                driver.driverID.toLowerCase().includes(value.toLowerCase()) ||
                driver.balance.toString().includes(value) ||
                driver.uzDriverId.toLowerCase().includes(value.toLowerCase()) ||
                driver.bankAccount.toLowerCase().includes(value.toLowerCase()) ||
                driver.accountStatus.toLowerCase().includes(value.toLowerCase())
            );
        });
        setFilteredDriversData(filteredData);
    };

    const handleSelectDriver = (driverID) => {
        setSelectedDrivers((prevSelected) => {
            if (prevSelected.includes(driverID)) {
                return prevSelected.filter(id => id !== driverID);
            } else {
                return [...prevSelected, driverID];
            }
        });
    };

    const openGenerateModal = () => {
        setIsGenerateModalVisible(true);
    };

    const closeGenerateModal = () => {
        setIsGenerateModalVisible(false);
    };

    const openGenerateZUSModal = () => {
        setIsGenerateZUSModalVisible(true);
    };

    const closeGenerateZUSModal = () => {
        setIsGenerateZUSModalVisible(false);
        setSelectedMonth(null);
    };

    const openBalancePopup = (driverID, action) => {
        setPopupDriverId(driverID);
        setBalanceAction(action);
        setIsBalancePopupVisible(true);
    };

    const closeBalancePopup = () => {
        setIsBalancePopupVisible(false);
        setBalanceInput('');
    };

    const openBankAccountModal = (driverID, bankAccount) => {
        setPopupDriverId(driverID);
        setBankAccountInput(bankAccount);
        setIsBankAccountModalVisible(true);
    };

    const closeBankAccountModal = () => {
        setIsBankAccountModalVisible(false);
        setBankAccountInput('');
    };

    const handleBankAccountSave = async () => {
        if (bankAccountInput.trim()) {
            try {
                const querySnapshot = await getDocs(query(collection(firestore, 'users'), where('driverId', '==', popupDriverId)));
                if (querySnapshot.size === 1) {
                    const userDoc = querySnapshot.docs[0];
                    const userID = userDoc.id;
                    const danePodatkoweRef = doc(firestore, `users/${userID}/dokumenty`, 'dane_podatkowe');
                    console.log(`Updating bank account for userID: ${userID}, path: users/${userID}/dokumenty/dane_podatkowe`);

                    await updateDoc(danePodatkoweRef, { rachunekBankowy: bankAccountInput });
                    message.success('Bank account updated successfully.');
                    closeBankAccountModal();
                    updateTable();
                } else {
                    message.error('Multiple or no user accounts found.');
                }
            } catch (error) {
                message.error("Failed to update bank account: " + error.message);
            }
        } else {
            message.error("Please enter a valid bank account.");
        }
    };

    const handleBalanceSave = async () => {
        if (balanceInput && !isNaN(parseFloat(balanceInput))) {
            try {
                const driverRef = ref(database, `drivers/${popupDriverId}`);
                
                const snapshot = await get(driverRef);
                const currentBalance = parseFloat(snapshot.val().balance || 0);
                const newBalance = (currentBalance + parseFloat(balanceInput) * (balanceAction === 'withdraw' ? -1 : 1)).toFixed(2);
    
                await update(driverRef, { balance: newBalance });
    
                message.success(`Balance ${balanceAction === 'withdraw' ? 'withdrawn' : 'deposited'} successfully.`);
                setBalanceInput('');
                closeBalancePopup();
                updateTable();
            } catch (error) {
                message.error("Failed to update balance: " + error.message);
            }
        } else {
            message.error("Please enter a valid amount.");
        }
    };

    const handleGenerateFile = async () => {
        let today = new Date();
        let paymentDate = ('0' + today.getDate()).slice(-2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + today.getFullYear();

        let fileContent = '4120414|1\r\n';
        let noBankAccountUsers = [];

        for (let driverID of selectedDrivers) {
            const driver = driversData.find(d => d.driverID === driverID);
            if (!driver) continue;

            const { bankAccount, balance } = driver;
            let balanceUmowaNajmu = Number(driver.balanceUmowaNajmu || 0).toFixed(2);

            if (bankAccount !== 'brak') {
                fileContent += `1|61109020080000000156542155|${bankAccount}|${driverID}|brak|${balance}|1|Rozliczenie|${paymentDate}|\r\n`;

                const driverRef = ref(database, `drivers/${driverID}`);
                await update(driverRef, { balance: 0, balanceUmowaNajmu: 0 })
                    .then(() => {
                        console.log(`Balance updated successfully for driver ${driverID}`);
                    })
                    .catch(error => {
                        console.error('Error updating balance:', error);
                    });
            } else {
                noBankAccountUsers.push(driverID);
            }
        }

        let blob = new Blob([fileContent], { type: 'text/plain' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'payments.txt';
        link.click();

        if (noBankAccountUsers.length > 0) {
            alert(`Could not process the following users due to missing bank accounts: ${noBankAccountUsers.join(', ')}`);
        }

        message.success('Plik został wygenerowany pomyślnie!');
        closeGenerateModal();
    };

    const handleGenerateZUSFile = async () => {
        if (!selectedMonth) {
            message.error('Please select a month.');
            return;
        }

        const month = moment(selectedMonth).format('MM');
        const year = moment(selectedMonth).format('YYYY');
        const paymentPeriod = `${month}-${year}`;

        const today = new Date();
        const paymentDate = moment(today).format('DD-MM-YYYY');

        let fileContent = '4120414|1\r\n';
        const noBankAccountUsers = [];
        const staticAmount = 220.29.toFixed(2);

        for (let driverID of selectedDrivers) {
            const driver = driversData.find(d => d.driverID === driverID);
            if (!driver) continue;

            const { bankAccount } = driver;

            if (bankAccount !== 'brak') {
                fileContent += `1|61109020080000000156542155|${bankAccount}|${driverID}|brak|${staticAmount}|1|Wyplata zgodnie z Umowa Zlecenia za okres ${paymentPeriod}|${paymentDate}|\r\n`;

                const driverRef = ref(database, `drivers/${driverID}`);
                const snapshot = await get(driverRef);
                const currentBalance = parseFloat(snapshot.val().balance || 0);
                const currentBalanceUmowaNajmu = parseFloat(snapshot.val().balanceUmowaNajmu || 0);

                const newBalance = (currentBalance - 220.29).toFixed(2);
                const newBalanceUmowaNajmu = (currentBalanceUmowaNajmu - 281.00).toFixed(2);

                await update(driverRef, {
                    balance: newBalance,
                    balanceUmowaNajmu: newBalanceUmowaNajmu
                }).catch(error => {
                    console.error('Error updating balance:', error);
                });
            } else {
                noBankAccountUsers.push(driverID);
            }
        }

        let blob = new Blob([fileContent], { type: 'text/plain' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'wyplata_zus.txt';
        link.click();

        if (noBankAccountUsers.length > 0) {
            alert(`Could not process the following users due to missing bank accounts: ${noBankAccountUsers.join(', ')}`);
        }

        message.success('Plik ZUS został wygenerowany pomyślnie!');
        closeGenerateZUSModal();
    };

    const columns = [
        {
            title: '',
            dataIndex: 'select',
            key: 'select',
            render: (_, record) => (
                <Checkbox
                    checked={selectedDrivers.includes(record.driverID)}
                    onChange={() => handleSelectDriver(record.driverID)}
                    disabled={parseFloat(record.balance) <= 0}
                />
            )
        },
        { 
            title: 'KR Driver ID', 
            dataIndex: 'driverID', 
            key: 'driverID', 
            sorter: (a, b) => a.driverID.localeCompare(b.driverID) 
        },
        { 
            title: 'Saldo', 
            dataIndex: 'balance', 
            key: 'balance', 
            sorter: (a, b) => parseFloat(a.balance) - parseFloat(b.balance) 
        },
        { title: 'UZ Driver ID', dataIndex: 'uzDriverId', key: 'uzDriverId' },
        {
            title: 'Rachunek Bankowy',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            render: (text, record) => (
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => openBankAccountModal(record.driverID, record.bankAccount)}>
                    {text}
                </span>
            ),
        },
        { title: 'Status konta', dataIndex: 'accountStatus', key: 'accountStatus' },
        {
            title: 'Wpłata gotówki',
            key: 'deposit',
            render: (_, record) => (
                <Button 
                    className="sign" 
                    onClick={() => openBalancePopup(record.driverID, 'deposit')}
                    disabled={parseFloat(record.balance) >= 0}
                >
                    Wpłata gotówki
                </Button>
            ),
        },
        {
            title: 'Wypłata gotówki',
            key: 'withdraw',
            render: (_, record) => (
                <Button 
                    className="signNon" 
                    onClick={() => openBalancePopup(record.driverID, 'withdraw')}
                    disabled={parseFloat(record.balance) < 0}
                >
                    Wypłata gotówki
                </Button>
            ),
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between' }}>
                <Input.Search
                    placeholder="Szukaj"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: '80%' }}
                />
                <div>
                    <Button
                        type="primary"
                        disabled={selectedDrivers.length === 0}
                        onClick={openGenerateModal}
                        style={{ marginLeft: '10px' }}
                    >
                        Generuj
                    </Button>
                    <Button
                        type="primary"
                        disabled={selectedDrivers.length === 0}
                        onClick={openGenerateZUSModal}
                        style={{ marginLeft: '10px' }}
                    >
                        Generuj ZUS
                    </Button>
                </div>
            </div>

            <Table columns={columns} dataSource={filteredDriversData} rowKey="key" pagination={false} />

            <div style={{ marginTop: '16px' }}>
                Łączna liczba kierowców: {filteredDriversData.length}
            </div>

            <Modal
                title="Generowanie pliku"
                visible={isGenerateModalVisible}
                onCancel={closeGenerateModal}
                footer={[
                    <Button key="cancel" onClick={closeGenerateModal}>
                        Anuluj
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleGenerateFile}>
                        Generuj
                    </Button>,
                ]}
            >
                <Table
                    dataSource={selectedDrivers.map(driverID => driversData.find(driver => driver.driverID === driverID))}
                    columns={[
                        { title: 'KR Driver ID', dataIndex: 'driverID', key: 'driverID' },
                        { title: 'Rachunek Bankowy', dataIndex: 'bankAccount', key: 'bankAccount' },
                        { title: 'Saldo', dataIndex: 'balance', key: 'balance' },
                    ]}
                    rowKey="driverID"
                    pagination={false}
                />
            </Modal>

            <Modal
                title="Wybierz miesiąc dla ZUS"
                visible={isGenerateZUSModalVisible}
                onCancel={closeGenerateZUSModal}
                footer={[
                    <Button key="cancel" onClick={closeGenerateZUSModal}>
                        Anuluj
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleGenerateZUSFile}>
                        Generuj ZUS
                    </Button>,
                ]}
            >
                <DatePicker
                    picker="month"
                    format="MM-YYYY"
                    value={selectedMonth}
                    onChange={(date) => setSelectedMonth(date)}
                    style={{ width: '100%', marginBottom: '16px' }}
                />

                <Table
                    dataSource={selectedDrivers.map(driverID => driversData.find(driver => driver.driverID === driverID))}
                    columns={[
                        { title: 'KR Driver ID', dataIndex: 'driverID', key: 'driverID' },
                        { title: 'Rachunek Bankowy', dataIndex: 'bankAccount', key: 'bankAccount' },
                        { title: 'Saldo', dataIndex: 'balance', key: 'balance' },
                    ]}
                    rowKey="driverID"
                    pagination={false}
                />
            </Modal>

            <Modal
                title={`Zmiana salda dla ${popupDriverId}`}
                visible={isBalancePopupVisible}
                onCancel={closeBalancePopup}
                footer={[
                    <Button key="cancel" onClick={closeBalancePopup} className="signNon">
                        Anuluj
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleBalanceSave} className="sign">
                        Zapisz
                    </Button>,
                ]}
            >
                <Input
                    placeholder={`Wpisz kwotę do ${balanceAction === 'withdraw' ? 'wypłaty' : 'wpłaty'}`}
                    value={balanceInput}
                    onChange={(e) => setBalanceInput(e.target.value)}
                />
            </Modal>

            <Modal
                title={`Zmień rachunek bankowy dla ${popupDriverId}`} // Заголовок для модального окна банковского счета
                visible={isBankAccountModalVisible}
                onCancel={closeBankAccountModal}
                footer={[
                    <Button key="cancel" onClick={closeBankAccountModal} className="signNon">
                        Anuluj
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleBankAccountSave} className="sign">
                        Zapisz
                    </Button>,
                ]}
            >
                <Input
                    placeholder="Wpisz nowy numer rachunku bankowego"
                    value={bankAccountInput}
                    onChange={(e) => setBankAccountInput(e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default Przelewy;
